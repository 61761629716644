import { Dialog } from "primereact/dialog";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { MaskField } from "react-mask-field";
import { create } from "react-modal-promise";
import { Button, Input, Radio, Select, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import "dayjs/locale/fr";
import { parseISO } from "date-fns";

const schema = yup
  .object({
    prenom: yup.string().required(),
    nom: yup.string().required(),
    nce: yup.string(),
    cni: yup.string().required(),
    sexe: yup.string().required(),
    dateDeNaissance: yup.string().required(),
    lieuDeNaissance: yup.string().required(),
    adresse: yup.string(),
    telephone: yup.string().required(),
    email: yup.string(),
    formation: yup.string().required(),
  })
  .required();

function UpdateEtudiantModal({ isOpen, onResolve, onReject, etudiant }) {
  const defaultValues = {
    _id: etudiant?._id,
    nce: etudiant?.nce,
    cni: etudiant?.cni ?? "",
    nom: etudiant?.nom,
    prenom: etudiant?.prenom,
    sexe: etudiant?.sexe,
    dateDeNaissance: etudiant?.dateDeNaissance,
    lieuDeNaissance: etudiant?.lieuDeNaissance,
    adresse: etudiant?.adresse,
    telephone: etudiant?.telephone,
    email: etudiant?.email,
    formation: etudiant?.formation,
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onCreate = (data) => {
    onResolve(data);
  };

  const formations = [
    {
      value: "SS MEDECINE GENERALE NIVEAU 1",
      label: "SS MEDECINE GENERALE NIVEAU 1",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS MEDECINE GENERALE NIVEAU 2",
      label: "SS MEDECINE GENERALE NIVEAU 2",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS MEDECINE GENERALE NIVEAU 3",
      label: "SS MEDECINE GENERALE NIVEAU 3",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS MEDECINE GENERALE NIVEAU 4",
      label: "SS MEDECINE GENERALE NIVEAU 4",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS MEDECINE GENERALE NIVEAU 5",
      label: "SS MEDECINE GENERALE NIVEAU 5",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS MEDECINE GENERALE NIVEAU 6",
      label: "SS MEDECINE GENERALE NIVEAU 6",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS MEDECINE GENERALE NIVEAU 7",
      label: "SS MEDECINE GENERALE NIVEAU 7",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS MEDECINE GENERALE NIVEAU 8",
      label: "SS MEDECINE GENERALE NIVEAU 8",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS LICENCE PROFESSIONNELLE BIOLOGIE MÉDICALE NIVEAU 1",
      label: "SS LICENCE PROFESSIONNELLE BIOLOGIE MÉDICALE NIVEAU 1",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS LICENCE PROFESSIONNELLE BIOLOGIE MÉDICALE NIVEAU 2",
      label: "SS LICENCE PROFESSIONNELLE BIOLOGIE MÉDICALE NIVEAU 2",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS LICENCE PROFESSIONNELLE BIOLOGIE MÉDICALE NIVEAU 3",
      label: "SS LICENCE PROFESSIONNELLE BIOLOGIE MÉDICALE NIVEAU 3",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS LICENCE SCIENCES OBSTÉTRICALES NIVEAU 1",
      label: "SS LICENCE SCIENCES OBSTÉTRICALES NIVEAU 1",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS LICENCE SCIENCES OBSTÉTRICALES NIVEAU 2",
      label: "SS LICENCE SCIENCES OBSTÉTRICALES NIVEAU 2",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS LICENCE SCIENCES OBSTÉTRICALES NIVEAU 3",
      label: "SS LICENCE SCIENCES OBSTÉTRICALES NIVEAU 3",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS LICENCE SOINS INFIRMIERS NIVEAU 1",
      label: "SS LICENCE SOINS INFIRMIERS NIVEAU 1",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS LICENCE SOINS INFIRMIERS NIVEAU 2",
      label: "SS LICENCE SOINS INFIRMIERS NIVEAU 2",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS LICENCE SOINS INFIRMIERS NIVEAU 3",
      label: "SS LICENCE SOINS INFIRMIERS NIVEAU 3",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SS MASTER THÉRAPIES DE SUPPLÉANCE RÉNALE NIVEAU 4",
      label: "SS MASTER THÉRAPIES DE SUPPLÉANCE RÉNALE NIVEAU 4",
      group: "UFR DES SCIENCES DE LA SANTE",
    },
    {
      value: "SES LICENCE SCIENCES JURIDIQUES NIVEAU 1",
      label: "SES LICENCE SCIENCES JURIDIQUES NIVEAU 1",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES LICENCE SCIENCES JURIDIQUES NIVEAU 2",
      label: "SES LICENCE SCIENCES JURIDIQUES NIVEAU 2",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES LICENCE DROIT DES AFFAIRES NIVEAU 3",
      label: "SES LICENCE DROIT DES AFFAIRES NIVEAU 3",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES LICENCE DROIT PUBLIC ÉCONOMIQUE NIVEAU 3",
      label: "SES LICENCE DROIT PUBLIC ÉCONOMIQUE NIVEAU 3",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES MASTER DROIT PRIVÉ NIVEAU 4",
      label: "SES MASTER DROIT PRIVÉ NIVEAU 4",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value:
        "SES MASTER DROIT PRIVÉ SPÉCIALITÉ DROIT PRIVÉ FONDAMENTAL NIVEAU 5",
      label:
        "SES MASTER DROIT PRIVÉ SPÉCIALITÉ DROIT PRIVÉ FONDAMENTAL NIVEAU 5",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value:
        "SES MASTER DROIT PRIVÉ SPÉCIALITÉ DROIT DES ACTIVITÉS ÉCONOMIQUES NIVEAU 5",
      label:
        "SES MASTER DROIT PRIVÉ SPÉCIALITÉ DROIT DES ACTIVITÉS ÉCONOMIQUES NIVEAU 5",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value:
        "SES MASTER DROIT PUBLIC SPÉCIALITÉ DROIT ET ADMINISTRATION DES COLLECTIVITÉS TERRITORIALES NIVEAU 4",
      label:
        "SES MASTER DROIT PUBLIC SPÉCIALITÉ DROIT ET ADMINISTRATION DES COLLECTIVITÉS TERRITORIALES NIVEAU 4",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value:
        "SES MASTER DROIT PUBLIC SPÉCIALITÉ DROIT ET ADMINISTRATION DES COLLECTIVITÉS TERRITORIALES NIVEAU 5",
      label:
        "SES MASTER DROIT PUBLIC SPÉCIALITÉ DROIT ET ADMINISTRATION DES COLLECTIVITÉS TERRITORIALES NIVEAU 5",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES LICENCE ECONOMIE GESTION NIVEAU 1",
      label: "SES LICENCE ECONOMIE GESTION NIVEAU 1",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES LICENCE ECONOMIE GESTION NIVEAU 2",
      label: "SES LICENCE ECONOMIE GESTION NIVEAU 2",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES LICENCE GESTION DES ENTREPRISES NIVEAU 3",
      label: "SES LICENCE GESTION DES ENTREPRISES NIVEAU 3",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES LICENCE ANALYSE ET POLITIQUE ÉCONOMIQUE NIVEAU 3",
      label: "SES LICENCE ANALYSE ET POLITIQUE ÉCONOMIQUE NIVEAU 3",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value:
        "SES MASTER FINDEV SPÉCIALITÉ EVALUATION D'IMPACT DES POLITIQUES DE DÉVELOPPEMENT NIVEAU 4",
      label:
        "SES MASTER FINDEV SPÉCIALITÉ EVALUATION D'IMPACT DES POLITIQUES DE DÉVELOPPEMENT NIVEAU 4",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value:
        "SES MASTER FINDEV SPÉCIALITÉ EVALUATION D'IMPACT DES POLITIQUES DE DÉVELOPPEMENT NIVEAU 5",
      label:
        "SES MASTER FINDEV SPÉCIALITÉ EVALUATION D'IMPACT DES POLITIQUES DE DÉVELOPPEMENT NIVEAU 5",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES MASTER FINDEV SPÉCIALITÉ FINANCE NIVEAU 4",
      label: "SES MASTER FINDEV SPÉCIALITÉ FINANCE NIVEAU 4",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES MASTER FINDEV SPÉCIALITÉ FINANCE NIVEAU 5",
      label: "SES MASTER FINDEV SPÉCIALITÉ FINANCE NIVEAU 5",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES LICENCE MANAGEMENT INFORMATISÉ DES ORGANISATIONS NIVEAU 1",
      label: "SES LICENCE MANAGEMENT INFORMATISÉ DES ORGANISATIONS NIVEAU 1",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES LICENCE MANAGEMENT INFORMATISÉ DES ORGANISATIONS NIVEAU 2",
      label: "SES LICENCE MANAGEMENT INFORMATISÉ DES ORGANISATIONS NIVEAU 2",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES LICENCE MANAGEMENT INFORMATISÉ DES ORGANISATIONS NIVEAU 3",
      label: "SES LICENCE MANAGEMENT INFORMATISÉ DES ORGANISATIONS NIVEAU 3",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value:
        "SES MASTER GESTION SPÉCIALITÉ MANAGEMENT DES SYSTÈMES D'INFORMATION AUTOMATISÉS NIVEAU 4",
      label:
        "SES MASTER GESTION SPÉCIALITÉ MANAGEMENT DES SYSTÈMES D'INFORMATION AUTOMATISÉS NIVEAU 4",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value:
        "SES MASTER GESTION SPÉCIALITÉ MANAGEMENT DES SYSTÈMES D'INFORMATION AUTOMATISÉS NIVEAU 5",
      label:
        "SES MASTER GESTION SPÉCIALITÉ MANAGEMENT DES SYSTÈMES D'INFORMATION AUTOMATISÉS NIVEAU 5",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value:
        "SES LICENCE PROFESSIONNELLE DUT+1 GESTION DE PROJETS ET CRÉATION D'ENTREPRISE NIVEAU 3",
      label:
        "SES LICENCE PROFESSIONNELLE DUT+1 GESTION DE PROJETS ET CRÉATION D'ENTREPRISE NIVEAU 3",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES LICENCE SOCIOLOGIE NIVEAU 1",
      label: "SES LICENCE SOCIOLOGIE NIVEAU 1",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES LICENCE SOCIOLOGIE NIVEAU 2",
      label: "SES LICENCE SOCIOLOGIE NIVEAU 2",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES LICENCE SOCIOLOGIE NIVEAU 3",
      label: "SES LICENCE SOCIOLOGIE NIVEAU 3",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value:
        "SES MASTER SOCIOLOGIE/POLITIQUES PUBLIQUES, CULTURES ET DÉVELOPPEMENT NIVEAU 4",
      label:
        "SES MASTER SOCIOLOGIE/POLITIQUES PUBLIQUES, CULTURES ET DÉVELOPPEMENT NIVEAU 4",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value:
        "SES MASTER SOCIOLOGIE SPÉCIALITÉ MIGRATION, SANTÉ ET DÉVELOPPEMENT NIVEAU 5",
      label:
        "SES MASTER SOCIOLOGIE SPÉCIALITÉ MIGRATION, SANTÉ ET DÉVELOPPEMENT NIVEAU 5",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES LICENCE TOURISME NIVEAU 1",
      label: "SES LICENCE TOURISME NIVEAU 1",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES LICENCE TOURISME NIVEAU 2",
      label: "SES LICENCE TOURISME NIVEAU 2",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "SES LICENCE TOURISME NIVEAU 3",
      label: "SES LICENCE TOURISME NIVEAU 3",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value:
        "SES MASTER TOURISME SPÉCIALITÉ MANAGEMENT DES ACTIVITÉS DU TOURISME ET DE LA CULTURE NIVEAU 4",
      label:
        "SES MASTER TOURISME SPÉCIALITÉ MANAGEMENT DES ACTIVITÉS DU TOURISME ET DE LA CULTURE NIVEAU 4",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value:
        "SES MASTER TOURISME SPÉCIALITÉ MANAGEMENT DES ACTIVITÉS DU TOURISME ET DE LA CULTURE NIVEAU 5",
      label:
        "SES MASTER TOURISME SPÉCIALITÉ MANAGEMENT DES ACTIVITÉS DU TOURISME ET DE LA CULTURE NIVEAU 5",
      group: "UFR SCIENCES ECONOMIQUES ET SOCIALES",
    },
    {
      value: "ST LICENCE PHYSIQUE CHIMIE NIVEAU 1",
      label: "ST LICENCE PHYSIQUE CHIMIE NIVEAU 1",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE PHYSIQUE CHIMIE NIVEAU 2",
      label: "ST LICENCE PHYSIQUE CHIMIE NIVEAU 2",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE PHYSIQUE CHIMIE NIVEAU 3",
      label: "ST LICENCE PHYSIQUE CHIMIE NIVEAU 3",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE MATHEMATIQUES PHYSIQUE INFORMATIQUE NIVEAU 1",
      label: "ST LICENCE MATHEMATIQUES PHYSIQUE INFORMATIQUE NIVEAU 1",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE MATHEMATIQUES PHYSIQUE INFORMATIQUE NIVEAU 2",
      label: "ST LICENCE MATHEMATIQUES PHYSIQUE INFORMATIQUE NIVEAU 2",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE MATHÉMATIQUES NIVEAU 3",
      label: "ST LICENCE MATHÉMATIQUES NIVEAU 3",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST MASTER MATHÉMATIQUES PURES NIVEAU 4",
      label: "ST MASTER MATHÉMATIQUES PURES NIVEAU 4",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST MASTER MATHÉMATIQUES PURES NIVEAU 5",
      label: "ST MASTER MATHÉMATIQUES PURES NIVEAU 5",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE INFORMATIQUE NIVEAU 3",
      label: "ST LICENCE INFORMATIQUE NIVEAU 3",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE CHIMIE NIVEAU 3",
      label: "ST LICENCE CHIMIE NIVEAU 3",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE INGÉNIERIE INFORMATIQUE NIVEAU 1",
      label: "ST LICENCE INGÉNIERIE INFORMATIQUE NIVEAU 1",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE INGÉNIERIE INFORMATIQUE NIVEAU 2",
      label: "ST LICENCE INGÉNIERIE INFORMATIQUE NIVEAU 2",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE INGÉNIERIE INFORMATIQUE NIVEAU 3",
      label: "ST LICENCE INGÉNIERIE INFORMATIQUE NIVEAU 3",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE AGROFORESTERIE NIVEAU 1",
      label: "ST LICENCE AGROFORESTERIE NIVEAU 1",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE AGROFORESTERIE NIVEAU 2",
      label: "ST LICENCE AGROFORESTERIE NIVEAU 2",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE AGROFORESTERIE NIVEAU 3",
      label: "ST LICENCE AGROFORESTERIE NIVEAU 3",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE GEOGRAPHIE NIVEAU 1",
      label: "ST LICENCE GEOGRAPHIE NIVEAU 1",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE GEOGRAPHIE NIVEAU 2",
      label: "ST LICENCE GEOGRAPHIE NIVEAU 2",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE GEOGRAPHIE NIVEAU 3",
      label: "ST LICENCE GEOGRAPHIE NIVEAU 3",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST LICENCE PROFESSIONNELLE AGRORESSOURCES VÉGÉTALES ET ENTREPRENARIAT NIVEAU 3",
      label:
        "ST LICENCE PROFESSIONNELLE AGRORESSOURCES VÉGÉTALES ET ENTREPRENARIAT NIVEAU 3",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST LICENCE PROFESSIONNELLE ENERGIES RENOUVELABLES ET EFFICACITE ENERGÉTIQUE NIVEAU 3",
      label:
        "ST LICENCE PROFESSIONNELLE ENERGIES RENOUVELABLES ET EFFICACITE ENERGÉTIQUE NIVEAU 3",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE PROFESSIONNELLE CREATION MULTIMEDIA NIVEAU 1",
      label: "ST LICENCE PROFESSIONNELLE CREATION MULTIMEDIA NIVEAU 1",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE PROFESSIONNELLE CREATION MULTIMEDIA NIVEAU 2",
      label: "ST LICENCE PROFESSIONNELLE CREATION MULTIMEDIA NIVEAU 2",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST LICENCE PROFESSIONNELLE CREATION MULTIMEDIA NIVEAU 3",
      label: "ST LICENCE PROFESSIONNELLE CREATION MULTIMEDIA NIVEAU 3",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST MASTER AGROFORESTERIE SPECIALITE AMENAGEMENT ET GESTION DURABLE DES ECOSYSTEMES FORESTIERS ET AGROFORESTIERS NIVEAU 4",
      label:
        "ST MASTER AGROFORESTERIE SPECIALITE AMENAGEMENT ET GESTION DURABLE DES ECOSYSTEMES FORESTIERS ET AGROFORESTIERS NIVEAU 4",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST MASTER AGROFORESTERIE SPECIALITE AMENAGEMENT ET GESTION DURABLE DES ECOSYSTEMES FORESTIERS ET AGROFORESTIERS NIVEAU 5",
      label:
        "ST MASTER AGROFORESTERIE SPECIALITE AMENAGEMENT ET GESTION DURABLE DES ECOSYSTEMES FORESTIERS ET AGROFORESTIERS NIVEAU 5",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST MASTER CHIMIE SPÉCIALITE CHIMIE DU SOLIDE ET DES MATERIAUX NIVEAU 4",
      label:
        "ST MASTER CHIMIE SPÉCIALITE CHIMIE DU SOLIDE ET DES MATERIAUX NIVEAU 4",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST MASTER CHIMIE SPÉCIALITE CHIMIE DU SOLIDE ET DES MATERIAUX NIVEAU 5",
      label:
        "ST MASTER CHIMIE SPÉCIALITE CHIMIE DU SOLIDE ET DES MATERIAUX NIVEAU 5",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST MASTER CHIMIE SPECIALITE SYNTHESE ORGANIQUE ET PRODUITS NATURELS NIVEAU 4",
      label:
        "ST MASTER CHIMIE SPECIALITE SYNTHESE ORGANIQUE ET PRODUITS NATURELS NIVEAU 4",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST MASTER CHIMIE SPECIALITE SYNTHESE ORGANIQUE ET PRODUITS NATURELS NIVEAU 5",
      label:
        "ST MASTER CHIMIE SPECIALITE SYNTHESE ORGANIQUE ET PRODUITS NATURELS NIVEAU 5",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST MASTER GÉOGRAPHIE / ESPACES, SOCIÉTÉS ET DÉVELOPPEMENT NIVEAU 4",
      label:
        "ST MASTER GÉOGRAPHIE / ESPACES, SOCIÉTÉS ET DÉVELOPPEMENT NIVEAU 4",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST MASTER GEOGRAPHIE SPÉCIALITÉ AMÊNAGEMENT ET TERRITOIRES NIVEAU 4",
      label:
        "ST MASTER GEOGRAPHIE SPÉCIALITÉ AMÊNAGEMENT ET TERRITOIRES NIVEAU 4",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST MASTER GEOGRAPHIE SPÉCIALITÉ AMÊNAGEMENT ET TERRITOIRES NIVEAU 5",
      label:
        "ST MASTER GEOGRAPHIE SPÉCIALITÉ AMÊNAGEMENT ET TERRITOIRES NIVEAU 5",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST MASTER GÉOGRAPHIE SPÉCIALITÉ ENVIRONNEMENT ET DÉVELOPPEMENT NIVEAU 5",
      label:
        "ST MASTER GÉOGRAPHIE SPÉCIALITÉ ENVIRONNEMENT ET DÉVELOPPEMENT NIVEAU 5",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST MASTER INFORMATIQUE SPÉCIALITÉ GÉNIE LOGICIEL NIVEAU 4",
      label: "ST MASTER INFORMATIQUE SPÉCIALITÉ GÉNIE LOGICIEL NIVEAU 4",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST MASTER INFORMATIQUE SPÉCIALITÉ GÉNIE LOGICIEL NIVEAU 5",
      label: "ST MASTER INFORMATIQUE SPÉCIALITÉ GÉNIE LOGICIEL NIVEAU 5",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST MASTER INFORMATIQUE SPÉCIALITÉ RÉSEAUX ET SYSTÈMES NIVEAU 4",
      label: "ST MASTER INFORMATIQUE SPÉCIALITÉ RÉSEAUX ET SYSTÈMES NIVEAU 4",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST MASTER INFORMATIQUE SPÉCIALITÉ RÉSEAUX ET SYSTÈMES NIVEAU 5",
      label: "ST MASTER INFORMATIQUE SPÉCIALITÉ RÉSEAUX ET SYSTÈMES NIVEAU 5",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST MASTER MATHÉMATIQUES ET APPLICATIONS SPÉCIALITÉ MATHÉMATIQUES APPLIQUÉES NIVEAU 4",
      label:
        "ST MASTER MATHÉMATIQUES ET APPLICATIONS SPÉCIALITÉ MATHÉMATIQUES APPLIQUÉES NIVEAU 4",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST MASTER MATHÉMATIQUES ET APPLICATIONS SPÉCIALITÉ MATHÉMATIQUES APPLIQUÉES NIVEAU 5",
      label:
        "ST MASTER MATHÉMATIQUES ET APPLICATIONS SPÉCIALITÉ MATHÉMATIQUES APPLIQUÉES NIVEAU 5",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST MASTER PHYSIQUE SPECIALITE INTERUNIVERSITAIRE EN ENERGIE RENOUVELABLE NIVEAU 4",
      label:
        "ST MASTER PHYSIQUE SPECIALITE INTERUNIVERSITAIRE EN ENERGIE RENOUVELABLE NIVEAU 4",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST MASTER PHYSIQUE SPECIALITE INTERUNIVERSITAIRE EN ENERGIE RENOUVELABLE NIVEAU 5",
      label:
        "ST MASTER PHYSIQUE SPECIALITE INTERUNIVERSITAIRE EN ENERGIE RENOUVELABLE NIVEAU 5",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST MASTER PHYSIQUE SPECIALITE PHYSIQUE DES MATÉRIAUX NIVEAU 4",
      label: "ST MASTER PHYSIQUE SPECIALITE PHYSIQUE DES MATÉRIAUX NIVEAU 4",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST MASTER INFORMATIQUE / RESEAUX ET SYSTEMES NIVEAU 4",
      label: "ST MASTER INFORMATIQUE / RESEAUX ET SYSTEMES NIVEAU 4",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST MASTER INFORMATIQUE / RESEAUX ET SYSTEMES NIVEAU 5",
      label: "ST MASTER INFORMATIQUE / RESEAUX ET SYSTEMES NIVEAU 5",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "ST MASTER PHYSIQUE SPECIALITE PHYSIQUE DES MATÉRIAUX NIVEAU 5",
      label: "ST MASTER PHYSIQUE SPECIALITE PHYSIQUE DES MATÉRIAUX NIVEAU 5",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST MASTER PHYSIQUE SPECIALITE SCIENCE DE L'ATMOSPHÈRE ET D'OCÉANOGRAPHIE NIVEAU 4",
      label:
        "ST MASTER PHYSIQUE SPECIALITE SCIENCE DE L'ATMOSPHÈRE ET D'OCÉANOGRAPHIE NIVEAU 4",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value:
        "ST MASTER PHYSIQUE SPECIALITE SCIENCE DE L'ATMOSPHÈRE ET D'OCÉANOGRAPHIE NIVEAU 5",
      label:
        "ST MASTER PHYSIQUE SPECIALITE SCIENCE DE L'ATMOSPHÈRE ET D'OCÉANOGRAPHIE NIVEAU 5",
      group: "UFR SCIENCES ET TECHNOLOGIES",
    },
    {
      value: "LASHU LICENCE HISTOIRE ET CIVILISATIONS NIVEAU 1",
      label: "LASHU LICENCE HISTOIRE ET CIVILISATIONS NIVEAU 1",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },
    {
      value: "LASHU LICENCE HISTOIRE ET CIVILISATIONS NIVEAU 2",
      label: "LASHU LICENCE HISTOIRE ET CIVILISATIONS NIVEAU 2",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },
    {
      value: "LASHU LICENCE HISTOIRE ET CIVILISATIONS NIVEAU 3",
      label: "LASHU LICENCE HISTOIRE ET CIVILISATIONS NIVEAU 3",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },
    {
      value: "LASHU LICENCE LANGUES ETRANGERES APPLIQUEES NIVEAU 1",
      label: "LASHU LICENCE LANGUES ETRANGERES APPLIQUEES NIVEAU 1",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },
    {
      value: "LASHU LICENCE LANGUES ETRANGERES APPLIQUEES NIVEAU 2",
      label: "LASHU LICENCE LANGUES ETRANGERES APPLIQUEES NIVEAU 2",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },
    {
      value: "LASHU LICENCE LANGUES ETRANGERES APPLIQUEES NIVEAU 3",
      label: "LASHU LICENCE LANGUES ETRANGERES APPLIQUEES NIVEAU 3",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },
    {
      value: "LASHU LICENCE LETTRES MODERNES NIVEAU 1",
      label: "LASHU LICENCE LETTRES MODERNES NIVEAU 1",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },
    {
      value: "LASHU LICENCE LETTRES MODERNES NIVEAU 2",
      label: "LASHU LICENCE LETTRES MODERNES NIVEAU 2",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },
    {
      value: "LASHU LICENCE LETTRES MODERNES NIVEAU 3",
      label: "LASHU LICENCE LETTRES MODERNES NIVEAU 3",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },
    {
      value:
        "LASHU MASTER LEA SPECIALITÉ COOPÉRATION INTERNATIONALE ET DÉVELOPPEMENT NIVEAU 4",
      label:
        "LASHU MASTER LEA SPECIALITÉ COOPÉRATION INTERNATIONALE ET DÉVELOPPEMENT NIVEAU 4",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },
    {
      value:
        "LASHU MASTER LEA SPECIALITÉ COOPÉRATION INTERNATIONALE ET DÉVELOPPEMENT NIVEAU 5",
      label:
        "LASHU MASTER LEA SPECIALITÉ COOPÉRATION INTERNATIONALE ET DÉVELOPPEMENT NIVEAU 5",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },
    {
      value:
        "LASHU MASTER LETTRES MODERNES SPÉCIALITÉ ETUDES LITTÉRAIRES NIVEAU 4",
      label:
        "LASHU MASTER LETTRES MODERNES SPÉCIALITÉ ETUDES LITTÉRAIRES NIVEAU 4",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },
    {
      value:
        "LASHU MASTER LETTRES MODERNES SPÉCIALITÉ ETUDES LITTÉRAIRES NIVEAU 5",
      label:
        "LASHU MASTER LETTRES MODERNES SPÉCIALITÉ ETUDES LITTÉRAIRES NIVEAU 5",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },
    {
      value:
        "LASHU MASTER LETTRES MODERNES SPÉCIALITÉ SCIENCE DU LANGAGE NIVEAU 4",
      label:
        "LASHU MASTER LETTRES MODERNES SPÉCIALITÉ SCIENCE DU LANGAGE NIVEAU 4",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },
    {
      value:
        "LASHU MASTER LETTRES MODERNES SPÉCIALITÉ SCIENCE DU LANGAGE NIVEAU 5",
      label:
        "LASHU MASTER LETTRES MODERNES SPÉCIALITÉ SCIENCE DU LANGAGE NIVEAU 5",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },
    {
      value: "LASHU MASTER SCIENCES HISTORIQUES NIVEAU 4",
      label: "LASHU MASTER SCIENCES HISTORIQUES NIVEAU 4",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },
    {
      value: "LASHU MASTER SCIENCES HISTORIQUES NIVEAU 5",
      label: "LASHU MASTER SCIENCES HISTORIQUES NIVEAU 5",
      group: "UFR LETTRES, ARTS ET SCIENCES HUMAINES",
    },

    {
      value: "DOCTORAT ARTS ET CIVILISATIONS NIVEAU 6",
      label: "DOCTORAT ARTS ET CIVILISATIONS NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT ARTS ET CIVILISATIONS NIVEAU 7",
      label: "DOCTORAT ARTS ET CIVILISATIONS NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT ARTS ET CIVILISATIONS NIVEAU 8",
      label: "DOCTORAT ARTS ET CIVILISATIONS NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT DROIT PRIVE NIVEAU 6",
      label: "DOCTORAT DROIT PRIVE NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT DROIT PRIVE NIVEAU 7",
      label: "DOCTORAT DROIT PRIVE NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT DROIT PRIVE NIVEAU 8",
      label: "DOCTORAT DROIT PRIVE NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT DROIT PUBLIC NIVEAU 6",
      label: "DOCTORAT DROIT PUBLIC NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT DROIT PUBLIC NIVEAU 7",
      label: "DOCTORAT DROIT PUBLIC NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT DROIT PUBLIC NIVEAU 8",
      label: "DOCTORAT DROIT PUBLIC NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT ECONOMIE NIVEAU 6",
      label: "DOCTORAT ECONOMIE NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT ECONOMIE NIVEAU 7",
      label: "DOCTORAT ECONOMIE NIVEAU 7",
      group: "ECOLE DOCTORALE SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT ECONOMIE NIVEAU 8",
      label: "DOCTORAT ECONOMIE NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT ETUDES HISTORIQUES NIVEAU 6",
      label: "DOCTORAT ETUDES HISTORIQUES NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT ETUDES HISTORIQUES NIVEAU 7",
      label: "DOCTORAT ETUDES HISTORIQUES NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT ETUDES HISTORIQUES NIVEAU 8",
      label: "DOCTORAT ETUDES HISTORIQUES NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT ETUDES LITTERAIRES NIVEAU 6",
      label: "DOCTORAT ETUDES LITTERAIRES NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT ETUDES LITTERAIRES NIVEAU 7",
      label: "DOCTORAT ETUDES LITTERAIRES NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT ETUDES LITTERAIRES NIVEAU 8",
      label: "DOCTORAT ETUDES LITTERAIRES NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES HUMAINES / DIDACTIQUES DES LANGUES NIVEAU 6",
      label: "DOCTORAT SCIENCES HUMAINES / DIDACTIQUES DES LANGUES NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES HUMAINES / DIDACTIQUES DES LANGUES NIVEAU 7",
      label: "DOCTORAT SCIENCES HUMAINES / DIDACTIQUES DES LANGUES NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES HUMAINES / DIDACTIQUES DES LANGUES NIVEAU 8",
      label: "DOCTORAT SCIENCES HUMAINES / DIDACTIQUES DES LANGUES NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES HUMAINES / GEOGRAPHIE NIVEAU 6",
      label: "DOCTORAT SCIENCES HUMAINES / GEOGRAPHIE NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES HUMAINES / GEOGRAPHIE NIVEAU 7",
      label: "DOCTORAT SCIENCES HUMAINES / GEOGRAPHIE NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES HUMAINES / GEOGRAPHIE NIVEAU 8",
      label: "DOCTORAT SCIENCES HUMAINES / GEOGRAPHIE NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES HUMAINES / HISTOIRE NIVEAU 6",
      label: "DOCTORAT SCIENCES HUMAINES / HISTOIRE NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES HUMAINES / HISTOIRE NIVEAU 7",
      label: "DOCTORAT SCIENCES HUMAINES / HISTOIRE NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES HUMAINES / HISTOIRE NIVEAU 8",
      label: "DOCTORAT SCIENCES HUMAINES / HISTOIRE NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES HUMAINES / SCIENCES DU LANGAGE NIVEAU 6",
      label: "DOCTORAT SCIENCES HUMAINES / SCIENCES DU LANGAGE NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES HUMAINES / SCIENCES DU LANGAGE NIVEAU 7",
      label: "DOCTORAT SCIENCES HUMAINES / SCIENCES DU LANGAGE NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES HUMAINES / SCIENCES DU LANGAGE NIVEAU 8",
      label: "DOCTORAT SCIENCES HUMAINES / SCIENCES DU LANGAGE NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES JURIDIQUES / DROIT PRIVE NIVEAU 6",
      label: "DOCTORAT SCIENCES JURIDIQUES / DROIT PRIVE NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES JURIDIQUES / DROIT PRIVE NIVEAU 7",
      label: "DOCTORAT SCIENCES JURIDIQUES / DROIT PRIVE NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES JURIDIQUES / DROIT PRIVE NIVEAU 8",
      label: "DOCTORAT SCIENCES JURIDIQUES / DROIT PRIVE NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES JURIDIQUES / DROIT PUBLIC NIVEAU 6",
      label: "DOCTORAT SCIENCES JURIDIQUES / DROIT PUBLIC NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES JURIDIQUES / DROIT PUBLIC NIVEAU 7",
      label: "DOCTORAT SCIENCES JURIDIQUES / DROIT PUBLIC NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES JURIDIQUES / DROIT PUBLIC NIVEAU 8",
      label: "DOCTORAT SCIENCES JURIDIQUES / DROIT PUBLIC NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value:
        "DOCTORAT SCIENCES JURIDIQUES / HISTOIRE DU DROIT ET DES INSTITUTIONS NIVEAU 6",
      label:
        "DOCTORAT SCIENCES JURIDIQUES / HISTOIRE DU DROIT ET DES INSTITUTIONS NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value:
        "DOCTORAT SCIENCES JURIDIQUES / HISTOIRE DU DROIT ET DES INSTITUTIONS NIVEAU 7",
      label:
        "DOCTORAT SCIENCES JURIDIQUES / HISTOIRE DU DROIT ET DES INSTITUTIONS NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value:
        "DOCTORAT SCIENCES JURIDIQUES / HISTOIRE DU DROIT ET DES INSTITUTIONS NIVEAU 8",
      label:
        "DOCTORAT SCIENCES JURIDIQUES / HISTOIRE DU DROIT ET DES INSTITUTIONS NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES SOCIALES / ANTHROPOLOGIE NIVEAU 6",
      label: "DOCTORAT SCIENCES SOCIALES / ANTHROPOLOGIE NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES SOCIALES / ANTHROPOLOGIE NIVEAU 7",
      label: "DOCTORAT SCIENCES SOCIALES / ANTHROPOLOGIE NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES SOCIALES / ANTHROPOLOGIE NIVEAU 8",
      label: "DOCTORAT SCIENCES SOCIALES / ANTHROPOLOGIE NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES SOCIALES / SOCIOLOGIE NIVEAU 6",
      label: "DOCTORAT SCIENCES SOCIALES / SOCIOLOGIE NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES SOCIALES / SOCIOLOGIE NIVEAU 7",
      label: "DOCTORAT SCIENCES SOCIALES / SOCIOLOGIE NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES SOCIALES / SOCIOLOGIE NIVEAU 8",
      label: "DOCTORAT SCIENCES SOCIALES / SOCIOLOGIE NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES SOCIALES / TOURISME NIVEAU 6",
      label: "DOCTORAT SCIENCES SOCIALES / TOURISME NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES SOCIALES / TOURISME NIVEAU 7",
      label: "DOCTORAT SCIENCES SOCIALES / TOURISME NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES SOCIALES / TOURISME NIVEAU 8",
      label: "DOCTORAT SCIENCES SOCIALES / TOURISME NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES ECONOMIQUES NIVEAU 6",
      label: "DOCTORAT SCIENCES ECONOMIQUES NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES ECONOMIQUES NIVEAU 7",
      label: "DOCTORAT SCIENCES ECONOMIQUES NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES ECONOMIQUES NIVEAU 8",
      label: "DOCTORAT SCIENCES ECONOMIQUES NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT GESTION NIVEAU 6",
      label: "DOCTORAT GESTION NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT GESTION NIVEAU 7",
      label: "DOCTORAT GESTION NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT GESTION NIVEAU 8",
      label: "DOCTORAT GESTION NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES DU LANGAGE ET DIDACTIQUES DES LANGUES NIVEAU 6",
      label: "DOCTORAT SCIENCES DU LANGAGE ET DIDACTIQUES DES LANGUES NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES DU LANGAGE ET DIDACTIQUES DES LANGUES NIVEAU 7",
      label: "DOCTORAT SCIENCES DU LANGAGE ET DIDACTIQUES DES LANGUES NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES DU LANGAGE ET DIDACTIQUES DES LANGUES NIVEAU 8",
      label: "DOCTORAT SCIENCES DU LANGAGE ET DIDACTIQUES DES LANGUES NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SOCIOLOGIE NIVEAU 6",
      label: "DOCTORAT SOCIOLOGIE NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SOCIOLOGIE NIVEAU 7",
      label: "DOCTORAT SOCIOLOGIE NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SOCIOLOGIE NIVEAU 8",
      label: "DOCTORAT SOCIOLOGIE NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT TOURISME NIVEAU 6",
      label: "DOCTORAT TOURISME NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT TOURISME NIVEAU 7",
      label: "DOCTORAT TOURISME NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT TOURISME NIVEAU 8",
      label: "DOCTORAT TOURISME NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT LETTRES / LITTERATURE NIVEAU 6",
      label: "DOCTORAT LETTRES / LITTERATURE NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT LETTRES / LITTERATURE NIVEAU 7",
      label: "DOCTORAT LETTRES / LITTERATURE NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT LETTRES / LITTERATURE NIVEAU 8",
      label: "DOCTORAT LETTRES / LITTERATURE NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES DE GESTION NIVEAU 6",
      label: "DOCTORAT SCIENCES DE GESTION NIVEAU 6",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES DE GESTION NIVEAU 7",
      label: "DOCTORAT SCIENCES DE GESTION NIVEAU 7",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value: "DOCTORAT SCIENCES DE GESTION NIVEAU 8",
      label: "DOCTORAT SCIENCES DE GESTION NIVEAU 8",
      group: "ECOLE DOCTORALE ESPACES,SOCIETES ET HUMANITES",
    },
    {
      value:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE DU SOLIDE ET DES MATERIAUX NIVEAU 6",
      label:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE DU SOLIDE ET DES MATERIAUX NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE DU SOLIDE ET DES MATERIAUX NIVEAU 7",
      label:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE DU SOLIDE ET DES MATERIAUX NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE DU SOLIDE ET DES MATERIAUX NIVEAU 8",
      label:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE DU SOLIDE ET DES MATERIAUX NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE ORGANIQUE ET ORGANOMETALIQUE NIVEAU 6",
      label:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE ORGANIQUE ET ORGANOMETALIQUE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE ORGANIQUE ET ORGANOMETALIQUE NIVEAU 7",
      label:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE ORGANIQUE ET ORGANOMETALIQUE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE ORGANIQUE ET ORGANOMETALIQUE NIVEAU 8",
      label:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE ORGANIQUE ET ORGANOMETALIQUE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE ORGANIQUE ET PRODUITS NATURELS NIVEAU 6",
      label:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE ORGANIQUE ET PRODUITS NATURELS NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE ORGANIQUE ET PRODUITS NATURELS NIVEAU 7",
      label:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE ORGANIQUE ET PRODUITS NATURELS NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE ORGANIQUE ET PRODUITS NATURELS NIVEAU 8",
      label:
        "DOCTORAT CHIMIE DES MATERIAUX / CHIMIE ORGANIQUE ET PRODUITS NATURELS NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT CHIMIE DES MATERIAUX / PHYSICO-CHIMIE DE LA MATIERE CONDENSEE NIVEAU 6",
      label:
        "DOCTORAT CHIMIE DES MATERIAUX / PHYSICO-CHIMIE DE LA MATIERE CONDENSEE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT CHIMIE DES MATERIAUX / PHYSICO-CHIMIE DE LA MATIERE CONDENSEE NIVEAU 7",
      label:
        "DOCTORAT CHIMIE DES MATERIAUX / PHYSICO-CHIMIE DE LA MATIERE CONDENSEE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT CHIMIE DES MATERIAUX / PHYSICO-CHIMIE DE LA MATIERE CONDENSEE NIVEAU 8",
      label:
        "DOCTORAT CHIMIE DES MATERIAUX / PHYSICO-CHIMIE DE LA MATIERE CONDENSEE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT GEOGRAPHIE / AMENAGEMENT NIVEAU 6",
      label: "DOCTORAT GEOGRAPHIE / AMENAGEMENT NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT GEOGRAPHIE / AMENAGEMENT NIVEAU 7",
      label: "DOCTORAT GEOGRAPHIE / AMENAGEMENT NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT GEOGRAPHIE / AMENAGEMENT NIVEAU 8",
      label: "DOCTORAT GEOGRAPHIE / AMENAGEMENT NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT GEOGRAPHIE / ENVIRONEMANT NIVEAU 6",
      label: "DOCTORAT GEOGRAPHIE / ENVIRONEMENT NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT GEOGRAPHIE / ENVIRONEMANT NIVEAU 7",
      label: "DOCTORAT GEOGRAPHIE / ENVIRONEMENT NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT GEOGRAPHIE / ENVIRONEMANT NIVEAU 8",
      label: "DOCTORAT GEOGRAPHIE / ENVIRONEMENT NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT INFORMATIQUE / DONNEES ET CONNAISSANCES NIVEAU 6",
      label: "DOCTORAT INFORMATIQUE / DONNEES ET CONNAISSANCES NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT INFORMATIQUE / DONNEES ET CONNAISSANCES NIVEAU 7",
      label: "DOCTORAT INFORMATIQUE / DONNEES ET CONNAISSANCES NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT INFORMATIQUE / DONNEES ET CONNAISSANCES NIVEAU 8",
      label: "DOCTORAT INFORMATIQUE / DONNEES ET CONNAISSANCES NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT INFORMATIQUE / MODELISATION,OPTIMISATION ET INGENIERIE DES PERFORMANCES NIVEAU 6",
      label:
        "DOCTORAT INFORMATIQUE / MODELISATION,OPTIMISATION ET INGENIERIE DES PERFORMANCES NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT INFORMATIQUE / MODELISATION,OPTIMISATION ET INGENIERIE DES PERFORMANCES NIVEAU 7",
      label:
        "DOCTORAT INFORMATIQUE / MODELISATION,OPTIMISATION ET INGENIERIE DES PERFORMANCES NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT INFORMATIQUE / MODELISATION,OPTIMISATION ET INGENIERIE DES PERFORMANCES NIVEAU 8",
      label:
        "DOCTORAT INFORMATIQUE / MODELISATION,OPTIMISATION ET INGENIERIE DES PERFORMANCES NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT INFORMATIQUE / RESEAUX ET SYSTEMES NIVEAU 6",
      label: "DOCTORAT INFORMATIQUE / RESEAUX ET SYSTEMES NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT INFORMATIQUE / RESEAUX ET SYSTEMES NIVEAU 7",
      label: "DOCTORAT INFORMATIQUE / RESEAUX ET SYSTEMES NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT INFORMATIQUE / RESEAUX ET SYSTEMES NIVEAU 8",
      label: "DOCTORAT INFORMATIQUE / RESEAUX ET SYSTEMES NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT INFORMATIQUE APPLIQUEE A L'INGENIERIE INNOVANTE NIVEAU 6",
      label:
        "DOCTORAT INFORMATIQUE APPLIQUEE A L'INGENIERIE INNOVANTE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT INFORMATIQUE APPLIQUEE A L'INGENIERIE INNOVANTE NIVEAU 7",
      label:
        "DOCTORAT INFORMATIQUE APPLIQUEE A L'INGENIERIE INNOVANTE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT INFORMATIQUE APPLIQUEE A L'INGENIERIE INNOVANTE NIVEAU 8",
      label:
        "DOCTORAT INFORMATIQUE APPLIQUEE A L'INGENIERIE INNOVANTE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT PHYSIQUE ET APPILICATIONS / MATERIAUX POUR ENERGIE / CONSTRUCTION / SOLAIRE THERMIQUE NIVEAU 6",
      label:
        "DOCTORAT PHYSIQUE ET APPILICATIONS / MATERIAUX POUR ENERGIE / CONSTRUCTION / SOLAIRE THERMIQUE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT PHYSIQUE ET APPILICATIONS / MATERIAUX POUR ENERGIE / CONSTRUCTION / SOLAIRE THERMIQUE NIVEAU 7",
      label:
        "DOCTORAT PHYSIQUE ET APPILICATIONS / MATERIAUX POUR ENERGIE / CONSTRUCTION / SOLAIRE THERMIQUE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT PHYSIQUE ET APPILICATIONS / MATERIAUX POUR ENERGIE / CONSTRUCTION / SOLAIRE THERMIQUE NIVEAU 8",
      label:
        "DOCTORAT PHYSIQUE ET APPILICATIONS / MATERIAUX POUR ENERGIE / CONSTRUCTION / SOLAIRE THERMIQUE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / ALGEBRE NIVEAU 6",
      label: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / ALGEBRE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / ALGEBRE NIVEAU 7",
      label: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / ALGEBRE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / ALGEBRE NIVEAU 8",
      label: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / ALGEBRE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / ANALYSE ET GEOMETRIE COMPLEXE NIVEAU 6",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / ANALYSE ET GEOMETRIE COMPLEXE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / ANALYSE ET GEOMETRIE COMPLEXE NIVEAU 7",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / ANALYSE ET GEOMETRIE COMPLEXE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / ANALYSE ET GEOMETRIE COMPLEXE NIVEAU 8",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / ANALYSE ET GEOMETRIE COMPLEXE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / BIOMATHEMATIQUE NIVEAU 6",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / BIOMATHEMATIQUE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / BIOMATHEMATIQUE NIVEAU 7",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / BIOMATHEMATIQUE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / BIOMATHEMATIQUE NIVEAU 8",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / BIOMATHEMATIQUE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / BIOMECANIQUE NIVEAU 6",
      label: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / BIOMECANIQUE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / BIOMECANIQUE NIVEAU 7",
      label: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / BIOMECANIQUE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / BIOMECANIQUE NIVEAU 8",
      label: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / BIOMECANIQUE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / CALCUL FORMEL NIVEAU 6",
      label: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / CALCUL FORMEL NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / CALCUL FORMEL NIVEAU 7",
      label: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / CALCUL FORMEL NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / CALCUL FORMEL NIVEAU 8",
      label: "DOCTORAT MATHEMATIQUES ET APPLICATIONS / CALCUL FORMEL NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / EDP ET MODELISATION NIVEAU 6",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / EDP ET MODELISATION NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / EDP ET MODELISATION NIVEAU 7",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / EDP ET MODELISATION NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / EDP ET MODELISATION NIVEAU 8",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / EDP ET MODELISATION NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / GEOMETRIE ALGEBRIQUE NIVEAU 6",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / GEOMETRIE ALGEBRIQUE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / GEOMETRIE ALGEBRIQUE NIVEAU 7",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / GEOMETRIE ALGEBRIQUE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / GEOMETRIE ALGEBRIQUE NIVEAU 8",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / GEOMETRIE ALGEBRIQUE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / GEOMETRIE DIFFERENTIELLE NIVEAU 6",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / GEOMETRIE DIFFERENTIELLE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / GEOMETRIE DIFFERENTIELLE NIVEAU 7",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / GEOMETRIE DIFFERENTIELLE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / GEOMETRIE DIFFERENTIELLE NIVEAU 8",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / GEOMETRIE DIFFERENTIELLE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / PROBABILITES ET STATISTIQUE NIVEAU 6",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / PROBABILITES ET STATISTIQUE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / PROBABILITES ET STATISTIQUE NIVEAU 7",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / PROBABILITES ET STATISTIQUE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / PROBABILITES ET STATISTIQUE NIVEAU 8",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / PROBABILITES ET STATISTIQUE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR COMPOSANTS ELECTRONIQUES / CAPTEURS NIVEAU 6",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR COMPOSANTS ELECTRONIQUES / CAPTEURS NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR COMPOSANTS ELECTRONIQUES / CAPTEURS NIVEAU 7",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR COMPOSANTS ELECTRONIQUES / CAPTEURS NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR COMPOSANTS ELECTRONIQUES / CAPTEURS NIVEAU 8",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR COMPOSANTS ELECTRONIQUES / CAPTEURS NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ELECTRONIQUE / PHOTOVOLTAIQUE NIVEAU 6",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ELECTRONIQUE / PHOTOVOLTAIQUE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ELECTRONIQUE / PHOTOVOLTAIQUE NIVEAU 7",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ELECTRONIQUE / PHOTOVOLTAIQUE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ELECTRONIQUE / PHOTOVOLTAIQUE NIVEAU 8",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ELECTRONIQUE / PHOTOVOLTAIQUE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ENERGIE / BIOENERGIE NIVEAU 6",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ENERGIE / BIOENERGIE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ENERGIE / BIOENERGIE NIVEAU 7",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ENERGIE / BIOENERGIE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ENERGIE / BIOENERGIE NIVEAU 8",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ENERGIE / BIOENERGIE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ENERGIE / CONSTRUCTION / SOLAIRE THERMIQUE NIVEAU 6",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ENERGIE / CONSTRUCTION / SOLAIRE THERMIQUE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ENERGIE / CONSTRUCTION / SOLAIRE THERMIQUE NIVEAU 7",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ENERGIE / CONSTRUCTION / SOLAIRE THERMIQUE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ENERGIE / CONSTRUCTION / SOLAIRE THERMIQUE NIVEAU 8",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / MATERIAUX POUR ENERGIE / CONSTRUCTION / SOLAIRE THERMIQUE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / OCEAN,ATMOSPHERE,SURFACES CONTINENTALES NIVEAU 6",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / OCEAN,ATMOSPHERE,SURFACES CONTINENTALES NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / OCEAN,ATMOSPHERE,SURFACES CONTINENTALES NIVEAU 7",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / OCEAN,ATMOSPHERE,SURFACES CONTINENTALES NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / OCEAN,ATMOSPHERE,SURFACES CONTINENTALES NIVEAU 8",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / OCEAN,ATMOSPHERE,SURFACES CONTINENTALES NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / PHYSIQUE ATOMIQUE ET NUCLEAIRE / PHYSIQUE MEDICALE NIVEAU 6",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / PHYSIQUE ATOMIQUE ET NUCLEAIRE / PHYSIQUE MEDICALE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / PHYSIQUE ATOMIQUE ET NUCLEAIRE / PHYSIQUE MEDICALE NIVEAU 7",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / PHYSIQUE ATOMIQUE ET NUCLEAIRE / PHYSIQUE MEDICALE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / PHYSIQUE ATOMIQUE ET NUCLEAIRE / PHYSIQUE MEDICALE NIVEAU 8",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / PHYSIQUE ATOMIQUE ET NUCLEAIRE / PHYSIQUE MEDICALE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / SPECTROSCOPIE ATOMIQUE / DIAGNOSTIC DES PLASMAS ASTROPHYSIQUES ET DE LABORATOIRE NIVEAU 6",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / SPECTROSCOPIE ATOMIQUE / DIAGNOSTIC DES PLASMAS ASTROPHYSIQUES ET DE LABORATOIRE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / SPECTROSCOPIE ATOMIQUE / DIAGNOSTIC DES PLASMAS ASTROPHYSIQUES ET DE LABORATOIRE NIVEAU 7",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / SPECTROSCOPIE ATOMIQUE / DIAGNOSTIC DES PLASMAS ASTROPHYSIQUES ET DE LABORATOIRE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / SPECTROSCOPIE ATOMIQUE / DIAGNOSTIC DES PLASMAS ASTROPHYSIQUES ET DE LABORATOIRE NIVEAU 8",
      label:
        "DOCTORAT MATHEMATIQUES ET APPLICATIONS / SPECTROSCOPIE ATOMIQUE / DIAGNOSTIC DES PLASMAS ASTROPHYSIQUES ET DE LABORATOIRE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT SCIENCES AGRONOMIQUES ET ENVIRONEMENT / AGROFORESTERIE ET PRODUCTIONS VEGETALES NIVEAU 6",
      label:
        "DOCTORAT SCIENCES AGRONOMIQUES ET ENVIRONEMENT / AGROFORESTERIE ET PRODUCTIONS VEGETALES NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT SCIENCES AGRONOMIQUES ET ENVIRONEMENT / AGROFORESTERIE ET PRODUCTIONS VEGETALES NIVEAU 7",
      label:
        "DOCTORAT SCIENCES AGRONOMIQUES ET ENVIRONEMENT / AGROFORESTERIE ET PRODUCTIONS VEGETALES NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DOCTORAT SCIENCES AGRONOMIQUES ET ENVIRONEMENT / AGROFORESTERIE ET PRODUCTIONS VEGETALES NIVEAU 8",
      label:
        "DOCTORAT SCIENCES AGRONOMIQUES ET ENVIRONEMENT / AGROFORESTERIE ET PRODUCTIONS VEGETALES NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DIPLOME D'ETAT DE DOCTEUR EN MEDECINE / MEDECINE GENERALE NIVEAU 6",
      label:
        "DIPLOME D'ETAT DE DOCTEUR EN MEDECINE / MEDECINE GENERALE NIVEAU 6",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DIPLOME D'ETAT DE DOCTEUR EN MEDECINE / MEDECINE GENERALE NIVEAU 7",
      label:
        "DIPLOME D'ETAT DE DOCTEUR EN MEDECINE / MEDECINE GENERALE NIVEAU 7",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
    {
      value:
        "DIPLOME D'ETAT DE DOCTEUR EN MEDECINE / MEDECINE GENERALE NIVEAU 8",
      label:
        "DIPLOME D'ETAT DE DOCTEUR EN MEDECINE / MEDECINE GENERALE NIVEAU 8",
      group: "ECOLE DOCTORALE SCIENCES,TECHNOLOGIES ET INGENIERIE",
    },
  ];

  return (
    <>
      <Dialog
        header="Modifier un Etudiant"
        visible={isOpen}
        onHide={() => onReject(false)}
        className="w-1/2"
      >
        <form onSubmit={handleSubmit(onCreate)} method="POST">
          <div>
            <Controller
              control={control}
              name="nce"
              render={({ field }) => (
                <TextInput
                  label="Numéro carte d'étudiant"
                  error={errors.nce && errors.nce.message}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="cni"
              render={({ field }) => (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  label="CNI"
                  error={errors.cni && errors.cni.message}
                  placeholder="CNI de l'étudiant"
                  withAsterisk
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="prenom"
              render={({ field }) => (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  label="Prenom"
                  error={errors.nom && errors.nom.message}
                  placeholder="prenom de l'étudiant"
                  withAsterisk
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="nom"
              render={({ field }) => (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  label="Nom"
                  error={errors.nom && errors.nom.message}
                  placeholder="Nom de l'étudiant"
                  withAsterisk
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="sexe"
              render={({ field }) => (
                <Radio.Group
                  value={field.value}
                  onChange={field.onChange}
                  name="sexe"
                  error={errors.sexe && errors.sexe.message}
                  label="Selectionnez le sexe"
                  withAsterisk
                >
                  <Radio value="H" label="HOMME" />
                  <Radio value="F" label="FEMME" />
                </Radio.Group>
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="dateDeNaissance"
              render={({ field }) => (
                <DatePicker
                  placeholder="Choisir la date de Naissance"
                  label="Date de Naissance"
                  withAsterisk
                  locale="fr"
                  value={parseISO(field.value)}
                  onChange={(v) => field.onChange(v.toISOString())}
                  error={
                    errors.dateDeNaissance && errors.dateDeNaissance.message
                  }
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="lieuDeNaissance"
              render={({ field }) => (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  label="lieu de Naissance"
                  error={
                    errors.lieuDeNaissance && errors.lieuDeNaissance.message
                  }
                  placeholder="Lieu de Naissance"
                  withAsterisk
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="adresse"
              render={({ field }) => (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  label="Adresse"
                  error={errors.adresse && errors.adresse.message}
                  placeholder="Adresse de l'étudiant"
                  withAsterisk
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  label="EMAIL"
                  error={errors.email && errors.email.message}
                  placeholder="Email de l'étudiant"
                />
              )}
            />
          </div>
          <div>
            <Controller
              control={control}
              name="telephone"
              render={({ field }) => (
                <Input.Wrapper
                  id="tel"
                  label="Téléphone"
                  error={errors.telephone && errors.telephone.message}
                  required
                >
                  <Input
                    component={MaskField}
                    mask="_________"
                    replacement={{ _: /\d/ }}
                    id="tel"
                    placeholder="Numéro de téléphone"
                    value={field.value}
                    onChange={field.onChange}
                  />
                </Input.Wrapper>
              )}
            />
          </div>
          <div className="mb-3">
            <Controller
              control={control}
              name="formation"
              render={({ field }) => (
                <Select
                  label="Formation"
                  placeholder="Selectionnez la formation ..."
                  searchable
                  clearable
                  nothingFound="Pas de formations disponibles"
                  data={formations}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.formation && errors.formation.message}
                />
              )}
            />
          </div>
          <div className="flex items-center justify-between">
            <div>
              <Button type="submit" className="bg-green-500 hover:bg-green-600">
                Modifer L'ETUDIANT
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
}

export default create(UpdateEtudiantModal);
